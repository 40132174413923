import { UserInteractionContext } from 'contexts/UserInteractionContext'
import PropTypes from 'prop-types'
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext
} from 'react'
import { TYPING_TIMEOUT } from 'components/MessageInput/config'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import ChatMessagesView from 'components/ChatFeedView'
import { createStructuredSelector } from 'reselect'
import { resetApp } from 'utils/localStorage'
import { selectConversationFromProps } from '../Conversations/selectors'
import {
  fetchConversation,
  fetchConversationMessages,
  messageRead,
  removeChatMessage,
  sendNewChatMessage,
  setTyping,
  updateConversation
} from './actions'
import { NEW_MESSAGE_ID } from './constants'
import { debounce } from 'lodash'
import { conversation as useChatMessageSocket } from '../../sockets'
import {
  selectChatMessages,
  selectError,
  selectIsLoading,
  selectIsMessageSending,
  selectShowTyping,
  selectGuestId
} from './selectors'

export function ChatFeed({
  conversation,
  conversationId,
  chatMessages,
  fetchChatMessages,
  fetchConversation,
  sendChatMessage,
  removeChatMessage,
  senderKey,
  senderId,
  guestId = 'Guest',
  isMessageSending,
  loading,
  onFocus,
  error,
  setTyping,
  showTyping,
  setMessageRead,
  updateConversation
}) {
  const [inc, setIncrement] = useState(0)
  const [gotInitialLoad, setGotInitialLoad] = useState(false)
  const userTyping = useRef()
  const typingTimoutRef = useRef()

  /* error */
  const [hasError, setError] = useState(false)

  /* SOCKET */
  let _send, socketConnected

  const onNewMessage = ({ message }) => {
    switch (message.action_type) {
      case 'participant_typing':
        // see https://github.com/robtaussig/react-use-websocket#example-options-with-ref-solution
        // - must use ref for dynamic props
        if (senderId === message.sender_id) break
        if (!userTyping?.current) {
          setTyping({ ...message, isTyping: true })
          userTyping.current = message.sender_id
        } else {
          clearTimeout(typingTimoutRef?.current)
        }
        typingTimoutRef.current = setTimeout(() => {
          if (userTyping?.current) {
            userTyping.current = null
            setTyping({ ...message, isTyping: false })
          }
        }, TYPING_TIMEOUT * 1.5)
        break
      case 'new_message':
        fetchChatMessages({ conversationId, senderId })
        break
      case 'message_seen':
        setMessageRead({
          id: message.chat_feed_item_id,
          conversation_id: conversationId,
          sender_id: message.sender_id,
          source: 'socket'
        })
        break
      default:
        console.log('unknown action type', message)
    }
  }

  [_send, socketConnected] = useChatMessageSocket({
    options: { onMessage: onNewMessage },
    conversationId
  })

  useEffect(() => {
    if (error) {
      setError(true)
    }
    return () => {
      setError(false)
    }
  }, [error])

  useEffect(() => {
    if (!conversationId || conversationId === 'new') {
      return
    }

    if (!conversation) {
      fetchConversation({ conversationId })
    }

    if (!loading && !chatMessages.length) {
      fetchChatMessages({ conversationId, senderId })
    }
    return () => {}
  }, [conversationId, socketConnected])

  const { hasFocused, focusWindow } = useContext(UserInteractionContext)

  useEffect(() => {
    // initial load will scroll to bottom for smooth scrolling
    if (chatMessages.length && !gotInitialLoad && socketConnected) {
      setTimeout(() => {
        setGotInitialLoad(true)
      }, 250)
    }
    if (chatMessages.length > 1 && !hasFocused) focusWindow()
    let a
    if (!gotInitialLoad && !loading) {
      a = setTimeout(() => {
        if (!gotInitialLoad) {
          setIncrement(inc + 1)
        }
      }, 1000)
      return () => {
        clearTimeout(a)
      }
    }
  }, [chatMessages, socketConnected, inc])

  const loadNextPage = debounce(() => {
    if (!conversationId) return
    fetchChatMessages({
      conversationId,
      senderId,
      isInitialLoad: false,
      offset: chatMessages.length
    })
  }, 500)

  const sendMessage = body => {
    sendChatMessage({
      body,
      [senderKey]: senderId,
      sender_id: senderId,
      conversation_id: conversationId,
      id: `${NEW_MESSAGE_ID}-${+new Date()}`,
      author: guestId,
      created_at: new Date()
    })
  }

  const sendTypingUpdate = useCallback(() => {
    _send(
      JSON.stringify({
        command: 'message',
        identifier: JSON.stringify({
          channel: 'ConversationsChannel',
          schema: 'dental_practices',
          conversation_id: conversationId
        }),
        data: JSON.stringify({
          action: 'participant_typing',
          action_type: 'participant_typing',
          conversation_id: conversationId,
          sender_id: senderId
        })
      })
    )
  }, [])

  const onMessageClick = message => {
    if (message.error) {
      removeChatMessage(message)
      sendChatMessage(message)
    }
  }

  const onOpenCloseClick = isClosed => {
    updateConversation({
      conversationId: conversation.id,
      status: isClosed ? 'open' : 'closed'
    })
  }

  const onInputFocus = e => {
    if (undefined !== onFocus) onFocus(e)
  }

  const onIntersect = evs => {
    evs.forEach(ev => {
      if (ev.intersectionRatio > 0) {
        ev.target.classList.add('in-viewport')
      } else {
        ev.target.classList.remove('in-viewport')
      }
      const id = ev.target.getAttribute('data-feed-id')
      const internal_id = ev.target.getAttribute('data-internal-id')
      const seen = ev.target.getAttribute('data-feed-seen')
      const source = ev.target.getAttribute('data-feed-source')
      if (ev.isIntersecting && seen.toString() === 'false') {
        setMessageRead({
          id,
          internal_id,
          conversation_id: conversationId,
          source
        })
      }
    })
  }

  if (hasError) {
    return 'error'
  }

  if (!senderId) {
    resetApp()
    return <Redirect to="/" />
  }

  return (
    <>
      <Helmet>
        <title>{userTyping?.current ? 'typing...' : 'Toothpic Chat'}</title>
        <meta name="description" content="Toothpic Chat" />
      </Helmet>

      <ChatMessagesView
        messages={chatMessages}
        sendMessage={sendMessage}
        // newMessage={chatMessages.newMessage}
        myId={senderId}
        isMessageSending={isMessageSending}
        onMessageClick={onMessageClick}
        isSmoothScroll={gotInitialLoad}
        loading={loading}
        loadNextPage={loadNextPage}
        total={chatMessages.total || -1}
        isInitialLoad={!gotInitialLoad}
        conversation={conversation}
        onFocus={onInputFocus}
        socketConnected={socketConnected}
        typingCallback={sendTypingUpdate}
        userTyping={showTyping}
        onIntersect={onIntersect}
        hasError={hasError}
        onOpenCloseClick={onOpenCloseClick}
      />
    </>
  )
}

ChatFeed.propTypes = {
  chatMessages: PropTypes.array
}

ChatFeed.defaultProps = {
  chatMessages: [],
  senderKey: 'assessor_id'
  // senderId: getUserId()
}

const mapStateToProps = (state, props) =>
  createStructuredSelector({
    error: selectError(),
    chatMessages: selectChatMessages(),
    conversation: selectConversationFromProps(),
    isMessageSending: selectIsMessageSending(),
    loading: selectIsLoading(),
    showTyping: selectShowTyping(),
    guestId: selectGuestId()
  })

function mapDispatchToProps(dispatch) {
  return {
    fetchChatMessages: params => dispatch(fetchConversationMessages(params)),
    fetchConversation: params => dispatch(fetchConversation(params)),
    sendChatMessage: params => dispatch(sendNewChatMessage(params)),
    removeChatMessage: params => dispatch(removeChatMessage(params)),
    updateConversation: params => dispatch(updateConversation(params)),
    setTyping: params => dispatch(setTyping(params)),
    // I have read a message
    setMessageRead: params => dispatch(messageRead(params)),
    // Someone else has read a message
    setMessageSeen: params => dispatch(messageRead(params))
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default withConnect(ChatFeed)
