export function getQueryParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " ")) || "{}";
}

export function getWindowToothpicOptions() {
  const w = window; //(window.parent && window.parent.window) || window
  if (!(w.ToothpicOptions && Object.keys(w.ToothpicOptions).length))
    throw new Error(
      "No ToothpicOptions are set! Please contact support@toothpic.com " +
      "if you need help setting up your messenger account"
    );
  return w.ToothpicOptions;
}

export function setWindowToothpicOptions(opts) {
  const w = window; //(window.parent && window.parent.window) || window
  w.ToothpicOptions = { ...w.ToothpicOptions, opts };
  return w.ToothpicOptions;
}
