import { CONVERSATION_SEEN } from "containers/ConnectedMessage/constants";
import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { sortMessages } from "../../utils/chatFeedItem";
import request, { requestPage } from "../../utils/request";
import {
  conversationSeenSuccess,
  fetchConversationFailure,
  fetchConversationMessagesFailure,
  fetchConversationMessagesSuccess,
  fetchConversationSuccess,
  sendNewChatMessageFailure,
  sendNewChatMessageSuccess,
  updateConversationFailure,
  updateConversationSuccess
} from "./actions";
import {
  FETCH_CHAT_FEED,
  FETCH_CONVERSATION,
  SEND_NEW_CHAT_MESSAGE,
  UPDATE_CONVERSATION
} from "./constants";

/*
 body
 conversation_id
 author
 status
 pending_at
 sent_at
 delivered_at
 read_at
 * [ + All basic crud and audit attributes]
 */

function getHasUnread(messages, myId) {
  return messages.some(m => {
    return m.sender_id !== myId && m.feed_item_type !== "form" && !m.seen_at;
  });
}

export function* getChatMessages({
  offset = 0,
  limit = 100,
  sort = "created_at:DESC",
  conversationId,
  senderId
}) {
  const chatMessagesEndpoint = `v3/chat_feed_items`;
  const headers = new Headers({ "x-schema": "dental_practices" });
  const options = {
    method: "GET",
    headers,
    mode: "cors"
  };
  const config = {
    params: `conversation_id=${conversationId}`,
    limit,
    offset,
    sort
  };
  const response = yield requestPage(chatMessagesEndpoint, options, config);
  // eslint-disable-next-line no-throw-literal
  if (response.data?.errors) throw response.data?.errors;
  return {
    ...response,
    data: sortMessages(response.data),
    hasUnread: getHasUnread(response.data, senderId)
  };
}

export function* fetchChatMessages({ payload }) {
  try {
    const response = yield getChatMessages(payload);
    yield put(fetchConversationMessagesSuccess({ ...payload, ...response }));
  } catch (error) {
    console.error(error);
    yield put(fetchConversationMessagesFailure(error, payload));
  }
}

export function* sendNewMessage({ payload: { sender_id, ...payload } }) {
  const messageEndpoint = "v3/messages";
  const headers = new Headers({ "x-schema": "dental_practices" });
  const options = {
    method: "POST",
    body: JSON.stringify(payload),
    headers
  };
  const oldId = payload.id
  delete payload.id
  delete payload.oldId
  try {
    const response = yield call(request, messageEndpoint, options);
    yield put(sendNewChatMessageSuccess({ ...response, oldId, sender_id }));
  } catch (e) {
    console.error(e);
    if (e) {
      e.message = "There was an error sending your message, tap to try again";
    }
    yield put(sendNewChatMessageFailure(payload, e));
  }
}

export function* fetchConversation({ payload }) {
  const messageEndpoint = `v3/conversations/${payload.conversationId}`;
  const headers = new Headers({ "x-schema": "dental_practices" });
  const options = {
    method: "GET",
    headers
  };
  try {
    const response = yield call(request, messageEndpoint, options);
    yield put(fetchConversationSuccess(response));
  } catch (e) {
    console.error(e);
    if (e) e.message = "There was an error loading this chat";
    yield put(fetchConversationFailure(e, payload));
  }
}

export function* markConversationSeen({ payload }) {
  const conversationEndpoint = `v3/conversations/${payload.conversationId}`;
  const headers = new Headers({ "x-schema": "dental_practices" });
  const options = {
    method: "PATCH",
    headers,
    body: JSON.stringify({ mark_all_as_seen: true })
  };
  try {
    yield call(request, conversationEndpoint, options);
    yield put(conversationSeenSuccess(payload.conversationId));
  } catch (e) {
    console.error(e);
    yield put(fetchConversationFailure(e, payload));
  }
}

export function* patchConversation({ payload }) {
  const messageEndpoint = `v3/conversations/${payload.conversationId}`;
  const headers = new Headers({ "x-schema": "dental_practices" });
  const options = {
    method: "PATCH",
    headers,
    body: JSON.stringify(payload)
  };
  try {
    const response = yield call(request, messageEndpoint, options);
    yield put(updateConversationSuccess(response));
  } catch (e) {
    console.error(e);
    if (e) e.message = "There was an error patching this conversation";
    yield put(updateConversationFailure(e, payload));
  }
}

export default function* chatMessagesSaga() {
  yield takeLatest(FETCH_CHAT_FEED, fetchChatMessages);
  yield takeEvery(SEND_NEW_CHAT_MESSAGE, sendNewMessage);
  yield takeLatest(FETCH_CONVERSATION, fetchConversation);
  yield takeEvery(CONVERSATION_SEEN, markConversationSeen);
  yield takeEvery(UPDATE_CONVERSATION, patchConversation);
}
