/*
 * MessageErrorView Messages
 *
 * This contains all the text for the MessageErrorView component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.MessageErrorView';

export default defineMessages({
  error: {
    id: `${scope}.error`,
    defaultMessage: 'Something has gone wrong, tap to try again',
  },
});
