/*
 *
 * Lead constants
 *
 */

export const DEFAULT_ACTION = "src/Lead/DEFAULT_ACTION";

export const GET_OR_CREATE_LEAD = "src/Lead/GET_OR_CREATE_LEAD";
export const GET_OR_CREATE_LEAD_FAILURE = "src/Lead/GET_OR_CREATE_LEAD_FAILURE";
export const GET_OR_CREATE_LEAD_SUCCESS = "src/Lead/GET_OR_CREATE_LEAD_SUCCESS";

export const CREATE_LEAD = "src/Lead/CREATE_LEAD";
export const CREATE_LEAD_FAILURE = "src/Lead/CREATE_LEAD_FAILURE";
export const CREATE_LEAD_SUCCESS = "src/Lead/CREATE_LEAD_SUCCESS";

export const GET_LEAD_OPEN_CONVERSATIONS = "src/Lead/GET_LEAD_OPEN_CONVERSATIONS";
export const GET_LEAD_OPEN_CONVERSATIONS_FAILURE = "src/Lead/GET_LEAD_OPEN_CONVERSATIONS_FAILURE";
export const GET_LEAD_OPEN_CONVERSATIONS_SUCCESS = "src/Lead/GET_LEAD_OPEN_CONVERSATIONS_SUCCESS";
