/*
 *
 * AccountGate reducer
 *
 */

import {
  DEFAULT_ACTION,
  LOAD_PRACTICE_ACCOUNT,
  LOAD_PRACTICE_ACCOUNT_FAILURE,
  LOAD_PRACTICE_ACCOUNT_SUCCESS
} from "./constants";

export const initialState = {
  account: null,
  error: null,
  loading: false,
  campaignId: null
};

function accountGateReducer(state = initialState, {type, payload, error}) {
  switch (type) {
    case DEFAULT_ACTION:
      return state;
    case LOAD_PRACTICE_ACCOUNT:
      return {...state, account: null, error: null, loading: true}
    case LOAD_PRACTICE_ACCOUNT_SUCCESS:
      return {...state, account: payload, error: null, loading: false, campaignId: payload.campaign_identifier}
    case LOAD_PRACTICE_ACCOUNT_FAILURE:
      return {...state, account: null, error: error?.message || error , loading: false}
    default:
      return state;
  }
}

export default accountGateReducer;
