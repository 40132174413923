/**
 *
 * Logo
 *
 */

import logo from './logo-image.svg'
import React from 'react'

// import styled from 'styled-components';

function Logo (props) {
  return <img src={logo} alt='toothpic logo' {...props} />
}

Logo.propTypes = {}

export default Logo
