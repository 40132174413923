import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the accountGate state domain
 */

const selectAccountGateDomain = state =>
  state.practiceAccount || initialState

/**
 * Other specific selectors
 */
export const selectAccount = () => createSelector(selectAccountGateDomain, substate => substate.account)
export const selectLoading = () => createSelector(selectAccountGateDomain, substate => substate.loading)
export const selectError = () => createSelector(selectAccountGateDomain, substate => substate.error)
export const selectCampaignIdentifier = () => createSelector(selectAccountGateDomain, substate => substate.campaignId)

/**
 * Default selector used by AccountGate
 */

const makeSelectAccountGate = () =>
    createSelector(selectAccountGateDomain, substate => substate);

export default makeSelectAccountGate;
export { selectAccountGateDomain };
