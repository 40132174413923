import {
  loadPracticeAccountFailure,
  loadPracticeAccountSuccess
} from "containers/AccountGate/actions";
import { selectLead } from "containers/Lead/selectors";
import { call, put, takeLatest } from "redux-saga/effects";
import { LOAD_PRACTICE_ACCOUNT } from "containers/AccountGate/constants";
import { setAuthToken } from "utils/localStorage";
import request from "utils/request";

function* loadAccount({ payload }) {
  try {

    const token = yield selectLead()?.auth_token
    if (token) {
      setAuthToken(token)
    }
    const appId = window.ToothpicOptions.appId
    if (!appId){
      return yield put(
        loadPracticeAccountFailure({ message: "No app Id available." })
      );
    }
    const endpoint =`v3/dental_practice_accounts?app_id=${appId}`;
    const headers = new Headers();
    const options = {
      method: "GET",
      headers,
      mode: "cors",
      // noAppId: true,
      noAuth: true
    };
    const response = yield call(request, endpoint, options);
    if (response && (!response.length || !response[0].campaign_identifier)){
      return yield put(loadPracticeAccountFailure({message:"No campaign identifier for company"}, payload, response));
    }
    yield put(loadPracticeAccountSuccess(response[0]));
  } catch (e) {
    console.error(e)
    yield put(loadPracticeAccountFailure(e, payload));
  }
}

export default function* accountGateSaga() {
  yield takeLatest(LOAD_PRACTICE_ACCOUNT, loadAccount);
}
