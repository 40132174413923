import './reconnecting-websocket'
import React from 'react'
import ReactDOM from 'react-dom'
import init from './onload'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { addDOMEvent } from 'utils/addDOMEvent'

let l = false
function onload () {
  if (l || !(document && document.body)) return
  l = true
  try {
    init()
      .then(() => {
        ReactDOM.render(<App />, document.getElementById('root'))
      })
      .catch(e => {
        ReactDOM.render(() => <h3>{e.message}</h3>, document.getElementById('root'))
        console.error(e)
      })
  } catch (e) {
    console.error(e)
  }
}

onload()
addDOMEvent('load', onload)
addDOMEvent('onload', onload)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
