import rootReducer from "reducers";
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import ConversationsSaga from "../containers/Conversations/saga";
import ChatMessagesSaga from "../containers/ChatFeed/saga";
import ConnectedMessagesSaga from "../containers/ConnectedMessage/saga";
import LeadSaga from "../containers/Lead/saga";
import PracticeAccountSaga from "../containers/AccountGate/saga";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware)
  // other store enhancers if any
);
const store = createStore(rootReducer, enhancer);

sagaMiddleware.run(ConversationsSaga);
sagaMiddleware.run(ChatMessagesSaga);
sagaMiddleware.run(ConnectedMessagesSaga);
sagaMiddleware.run(LeadSaga);
sagaMiddleware.run(PracticeAccountSaga);

export default store;
