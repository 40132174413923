/*
 *
 * ChatMessages constants
 *
 */

export const DEFAULT_ACTION = 'app/ChatFeed/DEFAULT_ACTION'
export const SEND_NEW_CHAT_MESSAGE = 'app/ChatFeed/SEND_NEW_CHAT_MESSAGE'
export const SEND_NEW_CHAT_MESSAGE_SUCCESS = 'app/ChatFeed/SEND_NEW_CHAT_MESSAGE_SUCCESS'
export const SEND_NEW_CHAT_MESSAGE_FAILURE = 'app/ChatFeed/SEND_NEW_CHAT_MESSAGE_ERROR'
export const REMOVE_CHAT_MESSAGE = 'app/ChatFeed/REMOVE_CHAT_MESSAGE'
export const FETCH_CHAT_FEED = 'app/ChatFeed/FETCH_CHAT_FEED'
export const FETCH_CHAT_FEED_SUCCESS = 'app/ChatFeed/FETCH_CHAT_FEED_SUCCESS'
export const FETCH_CHAT_FEED_FAILURE = 'app/ChatFeed/FETCH_CHAT_FEED_FAILURE'
export const FETCH_CONVERSATION = 'app/ChatFeed/FETCH_CONVERSATION'
export const FETCH_CONVERSATION_SUCCESS = 'app/ChatFeed/FETCH_CONVERSATION_SUCCESS'
export const FETCH_CONVERSATION_FAILURE = 'app/ChatFeed/FETCH_CONVERSATION_FAILURE'
export const NEW_MESSAGE_ID = 'new_message'
export const ADD_INIT_MESSAGE = 'TOOTHPIC/TPM/ADD_INIT_MESSAGE'
export const UPDATE_CONVERSATION = 'app/ChatMessages/UPDATE_CONVERSATION_STATUS'
export const UPDATE_CONVERSATION_SUCCESS = 'app/ChatMessages/UPDATE_CONVERSATION_SUCCESS'
export const UPDATE_CONVERSATION_FAILURE = 'app/ChatMessages/UPDATE_CONVERSATION_FAILURE'
