/**
 *
 * Loader
 *
 */

import React, { Component } from 'react'
import Lottie from 'react-lottie'
import loaderJson from './LoadingScreen.json'

class Loader extends Component {
  constructor (props) {
    super(props)
    this.elRef = React.createRef()
  }

  render () {
    const options = {
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loaderJson
    }

    return <Lottie options={options} height={400} width={400} />
  }
}

Loader.propTypes = {}

export default Loader
