/*
 *
 * Conversations reducer
 *
 */

import {
  CONVERSATION_SEEN,
  CONVERSATION_SEEN_SUCCESS,
  SET_TYPING
} from "../ConnectedMessage/constants";
import {
  CREATE_CONVERSATION,
  CREATE_CONVERSATION_FAILURE,
  CREATE_CONVERSATION_SUCCESS,
  DEFAULT_ACTION,
  GET_CONVERSATION_SUCCESS,
  LOAD_COMPANIES,
  LOAD_COMPANIES_FAILURE,
  LOAD_COMPANIES_SUCCESS,
  LOAD_CONVERSATIONS_LIST,
  LOAD_CONVERSATIONS_LIST_FAILURE,
  LOAD_CONVERSATIONS_LIST_SUCCESS,
  SET_CURRENT_CONVERSATION_ID
} from "./constants";

export const initialState = {
  conversationsList: [],
  error: null,
  currentConversation: null,
  typing: {},
  conversations: {}
};

function conversationsReducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case LOAD_CONVERSATIONS_LIST:
      return {
        ...state,
        isLoading: true,
        loadConversationsListFailure: null,
        error: null
      };
    case LOAD_CONVERSATIONS_LIST_SUCCESS:
      return payload.data.reduce(addConversation, state);
    case LOAD_CONVERSATIONS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        conversationsList: null,
        loadConversationsListFailure: error?.message || error,
        error: error?.message || error
      };
    case LOAD_COMPANIES:
      return {
        ...state,
        isLoading: true,
        loadCompaniesError: null,
        error: null
      };
    case LOAD_COMPANIES_SUCCESS:
      return { ...state, isLoading: false, companies: payload };
    case LOAD_COMPANIES_FAILURE:
      return { ...state, isLoading: false, companies: [],
        error: error?.message || error,
        loadCompaniesError: error?.message || error };
    case SET_CURRENT_CONVERSATION_ID:
      return { ...state, currentConversation: payload.id };
    case CONVERSATION_SEEN:
      return state;
    case CONVERSATION_SEEN_SUCCESS:
      return state;
    case SET_TYPING:
      const typing = { ...state.typing };
      const { conversation_id, sender_id, isTyping } = payload;
      let typingConvo = typing[conversation_id];
      if (!typingConvo || typeof typingConvo === "object") {
        //persist
        typingConvo = new Set([sender_id]);
      }
      if (isTyping) {
        typingConvo.add(sender_id);
      } else {
        typingConvo.delete(sender_id);
      }
      return {
        ...state,
        typing: {
          ...state.typing,
          [conversation_id]: typingConvo
        }
      };
    case GET_CONVERSATION_SUCCESS: {
      const conversation = payload;
      const conversationsList = state.conversationsList.filter(
        c => c.id !== conversation.id
      );
      conversationsList.unshift(conversation);
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [conversation.id]: conversation,
          conversationsList
        }
      };
    }
    case CREATE_CONVERSATION:
      return {
        ...state,
        loading: true,
        createConversationError: null,
        error: null
      };
    case CREATE_CONVERSATION_SUCCESS:
      const s = addConversation(state, payload);
      s.currentConversationId = payload.id;
      return s;
    case CREATE_CONVERSATION_FAILURE:
      return {
        ...state,
        loading: false,
        createConversationError: error?.message || error,
        error: error?.message || error
      };
    case DEFAULT_ACTION:
      return state;
    default:
      return state;
  }
}

export function addConversation(state, conversation) {
  const c = {
    ids: [],
    idMap: {},
    ...conversation
  };

  const conversationsList = state.conversationsList.filter(
    c => c.id !== conversation.id
  );
  conversationsList.push(conversation);
  return {
    ...state,
    conversations: {
      ...state.conversations,
      [conversation.id]: {
        ...state.conversations[conversation.id],
        ...c
      }
    },
    conversationsList
  };
}

export default conversationsReducer;
