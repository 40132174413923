import { selectCampaignIdentifier } from 'containers/AccountGate/selectors'
import BrowserFingerprintHash from 'onload/fingerprint'
import { getConversationsList } from '../../containers/Conversations/saga'
import {
  createLeadFailure,
  createLeadSuccess,
  getLeadConversationsFailure,
  getLeadConversationsSuccess,
  getOrCreateLeadSuccess
} from '../../containers/Lead/actions'
import {
  GET_OR_CREATE_LEAD,
  CREATE_LEAD,
  GET_LEAD_OPEN_CONVERSATIONS
} from '../../containers/Lead/constants'
import { takeLatest, call, put, select } from 'redux-saga/effects'
import request from '../../utils/request'
import {
  sessionUUID,
  setLeadId,
  setLead,
  getLead,
  setAuthToken,
  clearData,
  setUserId,
  setUser
} from '../../utils/localStorage'

function * getOrCreateLead () {
  const lead = getLead()
  if (lead) {
    setAuthToken(lead.auth_token)
    setUserId(lead.id)
    setUser(lead)
    return yield put(getOrCreateLeadSuccess(lead))
  }
  yield clearData()
  return yield createLead()
}

function * createLead () {
  const campaignId = yield select(selectCampaignIdentifier())
  if (!campaignId) throw new Error('Campaign Identifier is required')
  const bfp = yield BrowserFingerprintHash()
  const body = {
    browser_fingerprint: bfp,
    campaign_identifier: campaignId
  }
  const headers = new window.Headers()
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  }

  const leadUrl = 'v3/leads'

  try {
    const response = yield call(request, leadUrl, options)
    setLead(response)
    setLeadId(response.id)
    setUserId(response.id)
    setUser(response)
    setAuthToken(response.auth_token)
    yield put(createLeadSuccess(response))
  } catch (e) {
    console.error(e)
    yield put(createLeadFailure({ message: e.message }))
  }
}

function * getLeadConversations ({ payload }) {
  try {
    const open = yield call(getConversationsList, {
      payload: {
        offset: 0,
        limit: 1,
        sort: 'updated_at:DESC',
        params: 'status=open'
      }
    })
    const closed = yield call(getConversationsList, {
      payload: {
        offset: 0,
        limit: 1,
        sort: 'updated_at:DESC',
        params: 'status=closed'
      }
    })
    yield put(getLeadConversationsSuccess({ open, closed }, payload))
  } catch (e) {
    console.error(e)
    yield put(
      getLeadConversationsFailure(
        { message: e.message, stack: e.stack },
        payload
      )
    )
  }
}

export default function * leadSaga () {
  yield takeLatest(GET_OR_CREATE_LEAD, getOrCreateLead)
  yield takeLatest(GET_LEAD_OPEN_CONVERSATIONS, getLeadConversations)
  yield takeLatest(CREATE_LEAD, createLead)
}
