/**
 *
 * UserSwitch
 *
 */

import React from "react";
import { Redirect } from "react-router-dom";

function UserSwitch({ userType = process.env.USER_TYPE || "lead" }) {
  let userKey;
  switch (userType) {
    case "assessor":
      userKey = "assessor_id";
      break;
    case "member":
      userKey = "member_id";
      break;
    case "lead":
      userKey = "lead_id";
      break;
    default:
      userKey = "lead_id";
  }

  // if (userType === 'lead'){
  return <Redirect to="/lead" />;
  // }
}

UserSwitch.propTypes = {};

export default UserSwitch;
