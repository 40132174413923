/*
 *
 * Lead reducer
 *
 */
import {
  CREATE_CONVERSATION,
  CREATE_CONVERSATION_FAILURE,
  CREATE_CONVERSATION_SUCCESS
} from "../../containers/Conversations/constants";
import { getLead, getLeadId, setAuthToken } from "../../utils/localStorage";
import {
  CREATE_LEAD,
  CREATE_LEAD_FAILURE,
  CREATE_LEAD_SUCCESS,
  DEFAULT_ACTION,
  GET_LEAD_OPEN_CONVERSATIONS,
  GET_LEAD_OPEN_CONVERSATIONS_FAILURE,
  GET_LEAD_OPEN_CONVERSATIONS_SUCCESS,
  GET_OR_CREATE_LEAD,
  GET_OR_CREATE_LEAD_FAILURE,
  GET_OR_CREATE_LEAD_SUCCESS
} from "./constants";

export const initialState = {
  leadId: getLeadId(),
  loading: false,
  lead: getLead(),
  hasFetchedConversations: false,
  leadConversationsLoading: false,
  newChatMessages: [],
  currentConversationId: null,
  currentConversation: null,
  conversationsList: [],

  leadError: null,
  leadConversationsError: null,
  leadConversationCreateError: null
};

function leadReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;

    case GET_OR_CREATE_LEAD:
      return { ...state, loading: true, leadError: null, error: null };
    case GET_OR_CREATE_LEAD_SUCCESS:
      setAuthToken(action.payload.auth_token)
      return {
        ...state,
        loading: false,
        leadError: null,
        error: null,
        lead: action.payload,
        leadId: action.payload.id
      };
    case GET_OR_CREATE_LEAD_FAILURE:
      return {
        ...state,
        loading: false,
        leadError: action.error
      };

    case CREATE_LEAD:
      return {
        ...state,
        loading: true,
        leadError: null
      };

    case CREATE_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        lead: action.payload,
        leadId: action.payload.id,
        leadError: null
      };

    case CREATE_LEAD_FAILURE:
      return {
        ...state,
        loading: false,
        leadError: action.error
      };

    case GET_LEAD_OPEN_CONVERSATIONS:
      return {
        ...state,
        hasFetchedConversations: true,
        leadConversationsLoading: true,
        leadConversationsError: null,
        currentConversationId: null,
        currentConversation: null
      };
    case GET_LEAD_OPEN_CONVERSATIONS_SUCCESS:
      const { open, closed } = action.payload;
      const convo = open?.data[0];
      const hasOpen = open?.total > 0;
      const hasClosed = closed?.total > 0;
      const s = {
        ...state,
        leadConversationsLoading: false,
        hasFetchedConversations: true,
        leadConversationsError: null,
        hasOpen,
        hasClosed,
        open,
        closed
      };
      // if we have an open conversation, then go there
      if (convo) {
        s.currentConversationId = convo.id;
        s.currentConversation = convo;
      }

      return s;
    case GET_LEAD_OPEN_CONVERSATIONS_FAILURE:
      return {
        ...state,
        leadConversationsLoading: false,
        hasFetchedConversations: true,
        leadConversationsError: action.error.message
      };

    case CREATE_CONVERSATION:
      return {
        ...state,
        leadConversationCreateError: null,
        leadConversationsLoading: true
      };

    case CREATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        leadConversationsLoading: false,
        leadConversationCreateError: null,
        currentConversationId: action.payload.id
      };
    case CREATE_CONVERSATION_FAILURE:
      return {
        ...state,
        leadConversationsLoading: false,
        leadConversationCreateError: action.error?.message || action.error
      };
    default:
      return state;
  }
}

export default leadReducer;
