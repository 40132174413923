/**
 *
 * ConversationsView
 *
 */

import ChatHeader, { HeaderHeight } from "components/ChatHeader";
import ConversationListItem from "components/ConversationListItem";
import React from "react";
import styled from "styled-components";
import Button from 'components/Button'
import List from '@material-ui/core/List'

const ConversationsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: ${HeaderHeight}px;
`;

class ConversationsView extends React.Component {
  componentDidCatch(e, info) {
    // TODO: Error boundaries
    console.error(e, info);
  }
  render() {
    const { conversations = [], startNewConversation } = this.props;
    return (
      <ConversationsContainer>
        <ChatHeader title="Your previous conversations" />
        <List style={{marginTop: 120}}>
          {conversations.map(c => <ConversationListItem conversation={c}/>)}
        </List>
        <Button onClick={startNewConversation}>New</Button>
      </ConversationsContainer>
    );
  }
}

ConversationsView.propTypes = {};

export default ConversationsView;
