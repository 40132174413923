/*
 *
 * Lead actions
 *
 */

import {
  GET_OR_CREATE_LEAD,
  GET_OR_CREATE_LEAD_FAILURE,
  GET_OR_CREATE_LEAD_SUCCESS,
  CREATE_LEAD,
  CREATE_LEAD_SUCCESS,
  CREATE_LEAD_FAILURE,
  GET_LEAD_OPEN_CONVERSATIONS,
  GET_LEAD_OPEN_CONVERSATIONS_SUCCESS,
  GET_LEAD_OPEN_CONVERSATIONS_FAILURE,
  DEFAULT_ACTION } from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function getOrCreateLead () {
  return {
    type: GET_OR_CREATE_LEAD,
  }
}

export function getOrCreateLeadSuccess (payload) {
  return {
    type: GET_OR_CREATE_LEAD_SUCCESS,
    payload
  }
}

export function getOrCreateLeadFailure (error, payload) {
  return {
    type: GET_OR_CREATE_LEAD_FAILURE,
    error,
    payload
  }
}

export function createLead () {
  return {
    type: CREATE_LEAD,
  }
}
export function createLeadSuccess (payload) {
  return {
    type: CREATE_LEAD_SUCCESS,
    payload
  }
}
export function createLeadFailure (error, payload) {
  return {
    type: CREATE_LEAD_FAILURE,
    error,
    payload
  }
}


export function getLeadConversations (payload) {
  return {
    type: GET_LEAD_OPEN_CONVERSATIONS,
    payload
  }
}
export function getLeadConversationsSuccess (payload) {
  return {
    type: GET_LEAD_OPEN_CONVERSATIONS_SUCCESS,
    payload
  }
}
export function getLeadConversationsFailure (error, payload) {
  return {
    type: GET_LEAD_OPEN_CONVERSATIONS_FAILURE,
    error,
    payload
  }
}
