import ErrorBoundary from 'components/ErrorBoundary'
import AccountGate from 'containers/AccountGate'
import React from 'react'
import Routes from './routes'
import styled, { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { IFrameMessenger } from './utils/inter-frame-messaging'
import store from 'store'
import { UserInteractionContext } from 'contexts/UserInteractionContext'

import theme from './theme'

const persistor = persistStore(store)

window._purge = () =>
  persistor.purge().then(() => {
    return persistor.flush()
  })

window.messenger = new IFrameMessenger('chat')

const AppContainer = styled.div`
  position: fixed;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: flex;
  background: ${({ hasFocused }) => hasFocused ? 'white' : 'transparent'};
`

function App () {
  const ref = React.useRef()
  const onAppInteraction = () => {
    setUIContextValue({ ...UIContextValue, hasFocused: true })
    if (window.messenger) window.messenger.sendMessage({ type: 'focus-chat' })
  }
  const [UIContextValue, setUIContextValue] = React.useState({
    hasFocused: false,
    focusWindow: onAppInteraction
  })

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <AppContainer
          className='App'
          id='app'
          onClick={onAppInteraction}
          ref={ref}
          hasFocused={UIContextValue.hasFocused}
        >
          <UserInteractionContext.Provider value={UIContextValue}>
            <Provider store={store}>
              <PersistGate persistor={persistor} loading={null}>
                <AccountGate appId={window.ToothpicOptions.appId}>
                  <Routes />
                </AccountGate>
              </PersistGate>
            </Provider>
          </UserInteractionContext.Provider>
        </AppContainer>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default App
