/**
 *
 * ConversationListItem
 *
 */

import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Type } from '@toothpic/components/es/Type'
import { getTimeSinceCreation } from '../../utils/timeSince'
// import tracking from '../../tracking'
import withTrackClick from '@toothpic/utils/es/segment/withTrackClick'

const LinkItem = withTrackClick(styled(Link)`
 text-decoration: none;
 display: flex;
 flex: 1;
 flex-direction: column;
 padding: 8px;
`)

const ConversationPreview = styled(Type)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  min-height: 3em;
`

class ConversationListItem extends React.Component {
  render () {
    const { conversation } = this.props
    const timeSinceUpdate = getTimeSinceCreation(conversation.updated_at)
    return (
      <LinkItem to={`/conversations/${conversation.id}`}
        // trackingId={tracking.conversations.conversationsListItemClicked}
      >
        {/*<Type name='small/f/left/black/semi'>*/}
        {/*  {conversation?.patientAlias || 'Unknown'}*/}
        {/*</Type>*/}
        <Type name='tiny/f/right/black/light'>
          {timeSinceUpdate}
        </Type>
        {/*<ConversationPreview name='tiny/f/left/black/light' margin={0} component='span'>*/}
        {/*  {conversation.preview}*/}
        {/*</ConversationPreview>*/}
      </LinkItem>
    )
  }
}

ConversationListItem.propTypes = {
  conversation: PropTypes.object.isRequired// ,
  // viewConversation: PropTypes.func.isRequired
}

export default ConversationListItem
