/**
 *
 * MessageErrorView
 *
 */

import ChatHeader from "components/ChatHeader";
import React, { useEffect } from "react";
import styled from "styled-components";
import messages from "./messages";

const ErrorView = styled.div`
  padding: 16px;
  display: block;
  margin: 108px auto;
`;

function MessageErrorView({ onClear, error }) {
  useEffect(() => {
    console.error(error);
    return () => {};
  }, [error]);
  const onErrorClear = () => {
    if (window._purge) {
      window._purge().then(() => {
        window.location.href = "/";
      });
    } else {
      window.location.href = "/";
    }
  };

  const _onClear = e => {
    if (onClear) {
      return onClear(e);
    }
    return onErrorClear();
  };

  return (
    <ErrorView>
      <ChatHeader
        title="Something has gone wrong..."
        connected={false}
        userTyping={false}
      />
      <p>{error?.message || messages.error.defaultMessage}</p>
      <button onClick={_onClear}>Dismiss</button>
    </ErrorView>
  );
}

MessageErrorView.propTypes = {};

export default MessageErrorView;
