/**
 *
 * Conversations
 *
 */

import { selectCurrentConversation } from "containers/Conversations/selectors";
import ConversationsView from "../../components/ConversationsView";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getLeadId, getUser } from "../../utils/localStorage";
import { createConversation, loadConversationsList } from "./actions";
import { selectConversationsList, selectLoading } from "./selectors";
import { company as useCompanyConversationsSocket } from "sockets";
import { Redirect } from "react-router-dom";

export function Conversations({
  conversations,
  loadData,
  createConversation,
  currentConversation
}) {
  const [companyId] = useState(getUser()?.company_id);
  useEffect(() => {
    loadData();
    return () => {};
  }, []);

  const onNewMessage = () => {
    loadData();
  };

  const startNewConversation = () => {
    const leadId = getLeadId();
    createConversation({ lead_id: leadId });
  };

  useCompanyConversationsSocket({
    options: { onMessage: onNewMessage },
    companyId
  });

  if (currentConversation) {
    return <Redirect to={`/conversation/${currentConversation.id}`} />;
  }

  return (
    <ConversationsView
      conversations={conversations}
      startNewConversation={startNewConversation}
    />
  );
}

Conversations.defaultProps = {
  conversationsList: [],
  isLoading: true
};

Conversations.propTypes = {
  loadData: PropTypes.func.isRequired,
  conversationsList: PropTypes.array,
  isLoading: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  conversations: selectConversationsList(),
  isLoading: selectLoading(),
  currentConversation: selectCurrentConversation()
});

const mapDispatchToProps = dispatch => ({
  loadData: (...params) => {
    dispatch(loadConversationsList.call(null, params));
  },
  createConversation: params => dispatch(createConversation(params))
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Conversations);
