/*
See https://stackoverflow.com/questions/4177803/can-html5-databases-and-localstorage-be-shared-across-subdomains/24349708#24349708
 */
/**
 * Sends messages from an iframe to its parent
 */
export class IFrameMessenger {

  constructor(id) {
    this.id = id || `iframe-messenger-${Date.now()}`;
    this.parent = window.parent;
    this.init();
  }

  init() {
    window.addEventListener("message", e => {
      try {
        if (typeof e.data === 'string') this.onMessage(JSON.parse(e.data));
        else this.onMessage(e.data)
      } catch (err) {
        console.error(err);
      }
    });
  }

  registerCallback(eventName, callback) {
    this.callbacks[eventName] = callback;
  }

  sendMessage(message) {
    let m
    if (!this.parent.postMessage) return;
    try {
      m = JSON.stringify({ ...message, senderId: this.id })
    } catch (e) {
      // console.log(e)
      m = ''
    }
    this.parent.postMessage(m,
      "*"
    );
  }

  onMessage(message) {
    if (message.type && this.callbacks && this.callbacks[message.type])
      this.callbacks[message.type](message);
  }
}
