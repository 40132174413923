import uid from 'uniqid'

const sessionKey = 'tp-s-uuid'
const leadKey = 'tp-lead'
const leadIdKey = 'tp-lead-id'
const tokenKey = 'tp-auth-token'
const apiKey = 'tp-api-key'
const userIdKey = 'tp-user-id'
const userKey = 'tp-user'
const memberIdKey = 'tp-member-id'
const appId = 'tp-app-id'

export function sessionUUID () {
  let uuid = window.localStorage.getItem(sessionKey)
  if (!uuid) {
    uuid = uid()
    window.localStorage.setItem(sessionKey, uuid)
  }
  return uuid
}

export function clearSessionUUID () {
  window.localStorage.removeItem(sessionKey)
}

export function getLeadId () {
  return window.localStorage.getItem(leadIdKey)
}

export function getLead () {
  const lead = window.localStorage.getItem(leadKey)
  if (lead) {
    try {
      return JSON.parse(lead)
    } catch (e) {}
  }
  removeLeadId()
  removeLead()
  return null
}

export function setLeadId (id) {
  window.localStorage.setItem(leadIdKey, id)
}

export function setLead (lead) {
  window.localStorage.setItem(leadKey, JSON.stringify(lead))
}

export function removeLeadId () {
  window.localStorage.removeItem(leadIdKey)
  window.localStorage.removeItem(`${leadIdKey}-t`)
}

export function removeLead () {
  window.localStorage.removeItem(leadKey)
  window.localStorage.removeItem(`${leadKey}-t`)
}

export function setAuthToken (token, ttl) {
  window.localStorage.setItem(tokenKey, token)
  window.localStorage.setItem(`${tokenKey}-t`, +new Date() + ttl)
}

export function removeAuthToken () {
  window.localStorage.removeItem(tokenKey)
  window.localStorage.removeItem(`${tokenKey}-t`)
}

export function getAuthToken () {
  const t = window.localStorage.getItem(`${tokenKey}-t`)
  if (t && t < +new Date()) {
    removeAuthToken()
    return null
  }
  return window.localStorage.getItem(tokenKey)
}

export function setUserId (userId, ttl) {
  window.localStorage.setItem(userIdKey, userId)
  window.localStorage.setItem(`${userIdKey}-t`, +new Date() + ttl)
}

export function removeUserId () {
  window.localStorage.removeItem(userIdKey)
  window.localStorage.removeItem(`${userIdKey}-t`)
}

export function getUserId () {
  if (window.localStorage.getItem(`${userIdKey}-t`) < +new Date()) {
    removeUserId()
    return null
  }
  return window.localStorage.getItem(userIdKey)
}

export function setUser (user, ttl) {
  window.localStorage.setItem(userKey, JSON.stringify(user))
  window.localStorage.setItem(`${userKey}-t`, +new Date() + ttl)
}

export function removeUser () {
  window.localStorage.removeItem(userKey)
  window.localStorage.removeItem(`${userKey}-t`)
}

export function getUser () {
  if (window.localStorage.getItem(`${userKey}-t`) < +new Date()) {
    removeUser()
    return null
  }
  const u = window.localStorage.getItem(userKey)
  if (u) {
    try {
      return JSON.parse(u)
    } catch (e) {
      return null
    }
  }
  return null
}

export function setAPIKey (key) {
  window.localStorage.setItem(apiKey, key)
}

export function getAPIKey () {
  return window.localStorage.getItem(apiKey)
}

export function removeAPIKey () {
  window.localStorage.removeItem(apiKey)
}

export function setMemberId (id) {
  window.localStorage.setItem(memberIdKey, id)
}

export function removeMemberId () {
  window.localStorage.removeItem(memberIdKey)
}

export function getMemberId () {
  return window.localStorage.getItem(memberIdKey)
}

export function getAppId () {
  return window.localStorage.getItem(appId)
}

export function setAppId (id) {
  return window.localStorage.setItem(appId, id)
}

export function removeAppId () {
  return window.localStorage.removeItem(appId)
}

export function updateAppId (id) {
  console.log(id)
  const appId = getAppId()
  if (undefined !== appId && appId !== id) {
    return clearAllData().then(() => {
      setAppId(id)
      window.location.href = `/${window.location.search}`
    })
  }
  setAppId(id)
  return Promise.resolve()
}

export function clearData () {
  removeAuthToken()
  clearSessionUUID()
  removeLead()
  removeLeadId()
  removeMemberId()
  removeUserId()
  // clear redux persist
  return window._purge ? window._purge() : Promise.resolve()
}
export function clearAllData () {
  removeAppId()
  return clearData()
}

export function resetApp () {
  return clearAllData().then(
    () => (window.location.href = '/')
  )
}
//
// const vals = [sessionKey,leadIdKey,leadKey,tokenKey,apiKey,userIdKey,userKey,memberIdKey,appId]
// const keys = 'sessionKey,leadIdKey,leadKey,tokenKey,apiKey,userIdKey,userKey,memberIdKey,appId'.split(',')
// const map = keys.reduce((m,k,i)=>Object.assign(memo,{[k]:vals[i]}))
// export function set (keyOrId, value, options) {
//   const key = map[keyOrId] || keyOrId
//   window.localStorage.setItem(key, value)
//   if (options.ttl){
//
//   }
// }
