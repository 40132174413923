/*
 *
 * Conversations constants
 *
 */

export const DEFAULT_ACTION = 'app/Conversations/DEFAULT_ACTION'
export const START_POLL_CONVERSATIONS = 'app/Conversations/START_POLL_CONVERSATIONS'

export const CREATE_CONVERSATION = 'app/Conversations/CREATE_CONVERSATION'
export const CREATE_CONVERSATION_SUCCESS = 'app/Conversations/CREATE_CONVERSATION_SUCCESS'
export const CREATE_CONVERSATION_FAILURE = 'app/Conversations/CREATE_CONVERSATION_FAILURE'

export const LOAD_CONVERSATIONS_LIST = 'app/Conversations/LOAD_CONVERSATIONS_LIST'
export const LOAD_CONVERSATIONS_LIST_SUCCESS = 'app/Conversations/LOAD_CONVERSATIONS_LIST_SUCCESS'
export const LOAD_CONVERSATIONS_LIST_FAILURE = 'app/Conversations/LOAD_CONVERSATIONS_LIST_FAILURE'

export const LOAD_COMPANIES = 'app/Conversations/LOAD_COMPANIES'
export const LOAD_COMPANIES_SUCCESS = 'app/Conversations/LOAD_COMPANIES_SUCCESS'
export const LOAD_COMPANIES_FAILURE = 'app/Conversations/LOAD_COMPANIES_FAILURE'
export const SET_CURRENT_CONVERSATION_ID = 'app/Conversations/SET_CURRENT_CONVERSATION_ID'

export const GET_CONVERSATION = 'app/Conversations/GET_CONVERSATION'
export const GET_CONVERSATION_SUCCESS = 'app/Conversations/GET_CONVERSATION_SUCCESS'
export const GET_CONVERSATION_FAILURE = 'app/Conversations/GET_CONVERSATION_FAILURE'
