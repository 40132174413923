/*
 *
 * ChatFeed actions
 *
 */

import {
  DEFAULT_ACTION,
  FETCH_CONVERSATION,
  FETCH_CONVERSATION_FAILURE,
  FETCH_CHAT_FEED,
  FETCH_CHAT_FEED_FAILURE,
  FETCH_CHAT_FEED_SUCCESS,
  FETCH_CONVERSATION_SUCCESS,
  REMOVE_CHAT_MESSAGE,
  SEND_NEW_CHAT_MESSAGE,
  SEND_NEW_CHAT_MESSAGE_FAILURE,
  SEND_NEW_CHAT_MESSAGE_SUCCESS,
  UPDATE_CONVERSATION,
  UPDATE_CONVERSATION_SUCCESS,
  UPDATE_CONVERSATION_FAILURE
} from './constants'
import {
  CONVERSATION_SEEN,
  CONVERSATION_SEEN_SUCCESS, MESSAGE_READ, MESSAGE_READ_SUCCESS,
  MESSAGE_SEEN_CLEAR,
  SET_TYPING
} from "containers/ConnectedMessage/constants";

export function defaultAction () {
  return {
    type: DEFAULT_ACTION
  }
}

export function fetchConversationMessages (payload) {
  return {
    type: FETCH_CHAT_FEED,
    payload
  }
}

export function fetchConversationMessagesSuccess (payload) {
  return {
    type: FETCH_CHAT_FEED_SUCCESS,
    payload
  }
}

export function fetchConversationMessagesFailure (error, payload) {
  return {
    type: FETCH_CHAT_FEED_FAILURE,
    error,
    payload
  }
}

export function sendNewChatMessage (payload) {
  return {
    type: SEND_NEW_CHAT_MESSAGE,
    payload
  }
}

export function sendNewChatMessageSuccess (payload) {
  return {
    type: SEND_NEW_CHAT_MESSAGE_SUCCESS,
    payload
  }
}

export function sendNewChatMessageFailure (payload, error) {
  return {
    type: SEND_NEW_CHAT_MESSAGE_FAILURE,
    payload,
    error
  }
}

export function removeChatMessage (payload) {
  return {
    type: REMOVE_CHAT_MESSAGE,
    payload
  }
}

export function fetchConversation (payload) {
  return {
    type: FETCH_CONVERSATION,
    payload
  }
}

export function fetchConversationSuccess (payload) {
  return {
    type: FETCH_CONVERSATION_SUCCESS,
    payload
  }
}

export function fetchConversationFailure (error, payload) {
  return {
    type: FETCH_CONVERSATION_FAILURE,
    error,
    payload
  }
}

export function updateConversation (payload) {
  return {
    type: UPDATE_CONVERSATION,
    payload
  }
}

export function updateConversationSuccess (payload) {
  return {
    type: UPDATE_CONVERSATION_SUCCESS,
    payload
  }
}

export function updateConversationFailure (payload) {
  return {
    type: UPDATE_CONVERSATION_FAILURE,
    payload
  }
}

export function setTyping (payload) {
  return {
    type: SET_TYPING,
    payload
  }
}

export function messageRead (payload) {
  return {
    type: MESSAGE_READ,
    payload
  }
}

export function messageReadSuccess (payload) {
  return {
    type: MESSAGE_READ_SUCCESS,
    payload
  }
}

export function clearSeenMessages () {
  return {
    type: MESSAGE_SEEN_CLEAR
  }
}

export function conversationSeen (conversationId) {
  return {
    type: CONVERSATION_SEEN,
    payload: { conversationId }
  }
}

export function conversationSeenSuccess (conversationId) {
  return {
    type: CONVERSATION_SEEN_SUCCESS,
    payload: { conversationId }
  }
}
