/**
 *
 * AccountGate
 *
 */

import Loader from 'components/Loader'
import MessageErrorView from 'components/MessageErrorView'
import { loadPracticeAccount } from 'containers/AccountGate/actions'
import React, {Component} from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { selectAccount, selectError, selectLoading } from './selectors'

import PracticeContext from './context'
class AccountGate extends Component {
  componentDidMount () {
    this.loadAccount()
  }

  loadAccount () {
    const { load, loading, account } = this.props
    if (!account && !loading) {
      load()
    }
  }

  render () {
    const { loading, error, account, children } = this.props

    if ((error && !account) || !window.ToothpicOptions.appId) {
      return (
        <MessageErrorView
          error={{
            message:
              'There was an error with the associated App ID, please contact your Toothpic account administrator for help.'
          }}
        />
      )
    }

    if (loading) return <Loader key='loader' />

    return (
      <PracticeContext.Provider value={{ account }}>
        {children}
      </PracticeContext.Provider>
    )
  }
}

AccountGate.propTypes = {}

const mapStateToProps = createStructuredSelector({
  account: selectAccount(),
  loading: selectLoading(),
  error: selectError()
})

function mapDispatchToProps (dispatch) {
  return {
    load: appId => dispatch(loadPracticeAccount({ appId }))
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(AccountGate)
