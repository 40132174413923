/**
 *
 * ChatFeed
 *
 */

import React from "react";
import { getUserId } from "utils/localStorage";
import ChatFeed from './ChatFeed'

export default function({match}) {
  const senderKey = "lead_id"
  const senderId = getUserId()
  const conversationId = match.params.id
  return <ChatFeed senderId={senderId} senderKey={senderKey} conversationId={conversationId}/>
}
