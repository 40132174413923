/**
 *
 * Lead
 *
 * 1. land on home page (/)
 * 2. Go to /lead (/lead)
 * 3. Get lead (/v3/leads) if exists, create if not
 * 4. Go to lead page (/lead/{leadId})
 * 5. Get lead conversations (/v3/conversations)
 * 6. If conversation exists for lead already, move that that conversation ID (/conversation/{conversationId})
 * 7. Else go to (/conversation/new) and show default message
 * 8. On focus, move to new
 *
 * TODO: This should be a generic logic layer class that can be extended to each of member, lead, etc
 */
import { clearData, resetApp } from 'utils/localStorage'
import Loader from '../../components/Loader'
import MessageErrorView from '../../components/MessageErrorView/Loadable'
import {
  selectLoading,
  selectLead,
  selectCurrentConversationId,
  selectFetchedLeadConversations,
  selectLeadId,
  selectLeadConversationLoading,
  selectNewChatMessages,
  selectHasClosedConversation,
  selectLeadError,
  selectConversationsError,
  selectConversationCreateError
} from './selectors'
import {
  getOrCreateLead,
  getLeadConversations
} from '../../containers/Lead/actions'
import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { createConversation } from '../Conversations/actions'
import { Redirect } from 'react-router'

/**
 * With errors - looks for changes in those errors and updates an object with each
 * @param errorList Array<{{name: value}}> pairs
 * @returns [hasError, {{}}]
 */
export const useErrorList = errorList => {
  const [errors, setErrors] = useState({})
  const [hasError, setHasError] = useState(false)
  const _setErrorType = (error, type = 'error') => {
    setErrors({ ...errors, [type]: error })
    setHasError(true)
  }
  const clearError = (key = null) => {
    if (!key) {
      setErrors({})
      setHasError(false)
    } else {
      const _errors = Object.entries(errors)
        .filter(e => e[0] !== key)
        .reduce((m, en) => Object.assign(m, { [en[0]]: en[1] }))
      setErrors(_errors)
      setHasError(Object.keys(_errors).length)
    }
  }
  useEffect(() => {
    errorList.forEach(kvPair => {
      const [k, v] = Object.entries(kvPair)[0]
      if (v) {
        _setErrorType(v, k)
      }
    })

    return () => {
      setErrors({})
    }
  }, errorList)

  return [hasError, errors, clearError]
}

const useAppReset = (vars, time = 5000) => {
  let a
  useEffect(() => {
    a = setTimeout(() => {
      console.log('reset app')
      resetApp()
    }, time)
    return () => clearTimeout(a)
  }, vars)
  return null
}

function Lead({
  createConversation,
  conversationId,
  getLeadConversations,
  getOrCreateLead,
  hasClosed,
  hasFetchedConversations,
  leadConversationsLoading,
  lead,
  leadId,
  leadError,
  conversationError,
  conversationCreateError,
  loading,
  match
}) {

  console.log('render')

  // if we are still on this route after 5 secs, clear everything and
  useAppReset(
    [
      createConversation,
      conversationId,
      getLeadConversations,
      getOrCreateLead,
      hasClosed,
      hasFetchedConversations,
      leadConversationsLoading,
      lead,
      leadId,
      leadError,
      conversationError,
      conversationCreateError,
      loading,
      match
    ],
    3000
  )

  const errorList = useMemo(() => {
    return [
      { leadError },
      { conversationError },
      { conversationCreateError }
    ]
  }, [leadError,  conversationError, conversationCreateError]);
  const [hasError, errors] = useErrorList(errorList)

  if (hasError) {
    const _type = Object.keys(errors)[0]
    return <MessageErrorView error={errors[_type]} />
  }

  if (hasFetchedConversations && hasClosed && !leadConversationsLoading) {
    return <Redirect to="/conversations" />
  }

  if (conversationId && hasFetchedConversations && !leadConversationsLoading) {
    return <Redirect to={`/conversation/${conversationId}`} />
  }

  // go to lead if we have fetched
  if (
    leadId &&
    hasFetchedConversations &&
    !leadConversationsLoading &&
    !match.params?.id
  ) {
    return <Redirect to={`/lead/${leadId}`} />
  }

  // go to create a new lead if we have no lead in redux
  if (match?.params?.id && !leadId && match.path !== '/lead') {
    return <Redirect to="/lead" />
  }

  // get lead
  if (!lead && !loading && !match.params?.id) {
    getOrCreateLead()
    return <Loader  key='loader' />
  }

  // get lead
  if (
    leadId &&
    !leadConversationsLoading &&
    !hasFetchedConversations &&
    lead?.auth_token
  ) {
    getLeadConversations()
    return <Loader  key='loader' />
  }

  if (
    leadId &&
    hasFetchedConversations &&
    !loading &&
    !conversationId &&
    !leadConversationsLoading &&
    lead?.auth_token
  ) {
    createConversation(leadId)
  }

  return <Loader  key='loader' />
}

Lead.propTypes = {}

const mapStateToProps = createStructuredSelector({
  leadError: selectLeadError(),
  conversationError: selectConversationsError(),
  conversationCreateError: selectConversationCreateError(),
  loading: selectLoading(),
  leadConversationsLoading: selectLeadConversationLoading(),
  conversationId: selectCurrentConversationId(),
  hasFetchedConversations: selectFetchedLeadConversations(),
  hasClosed: selectHasClosedConversation(),
  lead: selectLead(),
  leadId: selectLeadId(),
  newChatMessages: selectNewChatMessages()
})

function mapDispatchToProps(dispatch) {
  return {
    createConversation: leadId =>
      dispatch(createConversation({ lead_id: leadId })),
    getLeadConversations: leadId =>
      dispatch(
        getLeadConversations({ lead_id: leadId, setOpenConversationId: true })
      ),
    getOrCreateLead: () => dispatch(getOrCreateLead())
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(Lead)
