import useSocket from './socket'

export default function useChatMessageSocket ({ options: { onMessage, ...options }, conversationId }) {
  const channelIdentifier = JSON.stringify({
    channel: 'ConversationsChannel',
    schema: 'dental_practices',
    conversation_id: conversationId
  })

  const _onMessage = (m) => {
    if (m.identifier === channelIdentifier) {
      onMessage(m)
    }
  }

  return useSocket({
    options: { ...options, onMessage: _onMessage },
    channelId: conversationId,
    channelName: 'ConversationsChannel',
    channelIdentifier
  })
}
