import { selectLeadDomain } from "containers/Lead/selectors";
import { createSelector } from 'reselect'
import { getLead } from "utils/localStorage";
import { initialState } from './reducer'

/**
 * Direct selector to the chatMessages state domain
 */

export const selectChatMessagesDomain = state =>
  state ? state.chatMessages : initialState

export const selectGuestId = () => createSelector(
  selectLeadDomain,
  (leadState) => {
    let guestId = leadState.lead?.guest_id
    if (!guestId){
      guestId = getLead()?.guest_id
    }
    if (!guestId){
      guestId = 'Guest'
    }
    return guestId
  }
)

/**
 * Other specific selectors
 */

export const selectChatMessages = () => createSelector(selectChatMessagesDomain,
  (state, props) => props.conversationId,
  (substate, conversationId) => {
    const convo = substate.conversations[conversationId]
    if (!convo?.ids) return []
    return convo.ids.map(id => substate.messages[id]) || []
  })

export const selectIsMessageSending = () => createSelector(selectChatMessagesDomain,
  (state, props) => props.conversationId,
  (substate, conversationId) => {
    return substate.conversations[conversationId]?.isMessageSending
  })

export const selectIsLoading = () => createSelector(selectChatMessagesDomain,
  (state, props) => props.conversationId,
  (substate, conversationId) => {
    return substate.conversations[conversationId]?.loading
  })

export const selectShowTyping = () => createSelector(selectChatMessagesDomain,
  (state, props) => props.conversationId,
  (substate, conversationId) => {
    return !!Object.keys(substate.typing[conversationId] || {}).length
  })

export const selectError = () => createSelector(selectChatMessagesDomain,
  (substate) => substate.error)
/**
 * Default selector used by ChatFeed
 */

const makeSelectChatMessages = () =>
  createSelector(selectChatMessagesDomain, substate => substate)

export default makeSelectChatMessages

// export { selectMessagesByConversationId, selectConversationByConversationId,selectcurrentConversation }
