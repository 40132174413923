import { createSelector } from 'reselect'
import { initialState } from './reducer'

export const reducerName = 'conversations'

/**
 * Direct selector to the conversations state domain
 */
const selectConversationsContainerDomain = state => state[reducerName] || initialState

/**
 * Other specific selectors
 */
const selectLoading = () => createSelector(selectConversationsContainerDomain, substate => substate.isLoading)
export const selectConversationFromProps = () => createSelector(
  selectConversationsContainerDomain,
  (state,props)=>props.conversationId,
  (conversationsDomain, id) => {
    return conversationsDomain.conversations[id] || null
  }
)

const selectCompanies = () => createSelector(selectConversationsContainerDomain, substate => substate.companies)

const selectCurrentConversation = () => createSelector(selectConversationsContainerDomain, substate => substate.currentConversation)

const selectConversationsList = () => createSelector(
  selectConversationsContainerDomain,
  (state, props) => props.match.params.view,
  (conversationsDomain, status) => {
    return conversationsDomain.conversationsList//.filter(conversation => conversation.status === status)
  }
)
const selectConversation = (id) => createSelector(
  selectConversationsContainerDomain,
  (conversationsDomain) => {
    return conversationsDomain.conversations[id] || null
  }
)

/**
 * Default selector used by Conversations
 */

const makeSelectConversationsContainer = () =>
  createSelector(selectConversationsContainerDomain, substate => substate)

export default makeSelectConversationsContainer
export {
  selectConversationsContainerDomain,
  selectLoading,
  selectConversationsList,
  selectCurrentConversation,
  selectCompanies,
  selectConversation
}
