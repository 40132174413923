import "whatwg-fetch";
import { addAhoyHeader } from "@toothpic/utils/es/ahoy";
import { getWindowToothpicOptions } from "utils/getOptions";
import { getResponse } from 'utils/mockResponses'
import { getAPIKey, getAuthToken } from "./localStorage";
import { unauthRoutes } from "./urls";

function getError(data) {
  return (
    data && data.errors && data.errors.length && data.errors[0].external_message
  );
}

class APIError extends Error {
  constructor(message, status) {
    super();
    this.message = message;
    this.status = status;
    this.time = +new Date();
  }
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (
    response.status === 204 ||
    response.status === 205 ||
    response.status === 202 ||
    response.status === 404 ||
    response.status === 406
  ) {
    return null;
  }
  return response.json().catch(e => null);
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status === 204) {
    return null;
  }

  if (response.status === 401 || response.status === 403) {
    // clearData();
    return Promise.reject("Unavailable.");
  }

  if (response.status === 404 || response.status === 406) {
    return Promise.reject("Not found");
  }

  if (response.status === 404 || response.status === 406) {
    return Promise.reject("Not found");
  }

  if (response.status >= 200 && response.status < 300) {
    return parseJSON(response);
  }

  // Get value from promise and throw error with custom messgae from backend
  return parseJSON(response).then(data => {
    const error = new APIError(
      getError(data) || "An error has occurred",
      response.status
    );
    console.error(error); // eslint-disable-line no-console
    return Promise.reject(error);
  });
}

const isUnAuthRoute = (url, options) =>
  unauthRoutes.some(u =>
    u instanceof RegExp
      ? u.test(url)
      : u[options.method] && u[options.method].test(url)
  );

/**
 * Delay in a promise chain
 * @param t
 * @returns {Promise<any>}
 */
const delay = t => new Promise(resolve => setTimeout(resolve, t));

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */

function retryRequest(url, options, retryContidion, nRetries = 3) {
  return nRetries
    ? fetch(url, options)
        .then(checkStatus)
        .then(data => {
          if (!retryContidion || !nRetries) {
            return data;
          }
          if (data && retryContidion(data)) {
            return delay(500 * 4 - nRetries).then(() =>
              // console.info(nRetries, 'retries left...', url);
              retryRequest(url, options, retryContidion, nRetries - 1)
            );
          }
          return data;
        })
    : Promise.reject(new Error(`Too many attempts: ${url}`));
}

function isLocalhost() {
  return window.location.href.indexOf('localhost') > -1
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options, retryContidion, nRetries = 3) {
  const fullURL = `${process.env.REACT_APP_API_URL}/${url}`;
  options.headers.append("X-Platform", "tpm");
  options.headers.set("X-Client-Version", process.env.REACT_APP_VERSION);
  options.headers.append("X-Api-Key", getAPIKey());
  addAhoyHeader(options.headers);

  const opts = getWindowToothpicOptions();
  const appId = opts.appId;

  if (appId && !options.noAppId) {
    options.headers.append('X-APP-ID', appId)
  }

  const token = getAuthToken();
  if (!token && !isUnAuthRoute(url, options)) {
    return Promise.reject('Unauthorized')
  }

  if (token && !options?.noAuth) {
    options.headers.append("X-Auth-Token", token);
  }

  if (options.method !== "DELETE") {
    options.headers.append("Content-Type", "application/json");
  }

  return fetch(fullURL, options)
    .then(checkStatus)
    .then(data => {
      if (!retryContidion || !nRetries) {
        return data;
      }
      if (data && retryContidion(data)) {
        return delay(500 * 4 - nRetries).then(() => {
          console.info(nRetries, "retries left...", fullURL);
          return retryRequest(fullURL, options, retryContidion, nRetries - 1);
        });
      }
      return data;
    });
}

export function* requestPage(url, options, config) {
  const {
    offset = 0,
    limit = 20,
    sort = "created_at:DESC",
    params = ""
  } = config;
  const endpoint = `${
    process.env.REACT_APP_API_URL
  }/${url}?limit=${limit}&offset=${offset}&sort=${sort}${
    params ? `&${params}` : ""
  }`;
  addAuditHeader(options.headers);
  addAhoyHeader(options.headers);

  const opts = getWindowToothpicOptions();
  const appId = opts.appId;

  if (appId) {
    options.headers.append('X-APP-ID', appId)
  }

  const token = getAuthToken();
  const key = getAPIKey();
  options.headers.append("X-Platform", "tpm");
  options.headers.append("X-Client-Version", process.env.REACT_APP_VERSION);
  if (token && !config.noToken) options.headers.append("X-Auth-Token", token);
  if (key && !config.noKey) options.headers.append("X-Api-Key", key);

  const response = yield fetch(endpoint, options)
  const data = yield checkStatus(response)
  const total = parseInt(response.headers.get("x-total-count"));
  return { data, total, limit, offset };
}

/**
 * If requesting from audit, then add audit to headers
 * @param headers
 */
export function addAuditHeader(headers) {
  if (/audit=true/.test(window.location.href)) {
    headers.set("audit", "true");
    headers.set("x-audit", "true");
  }
}
