import {
  getQueryParameterByName,
  getWindowToothpicOptions
} from 'utils/getOptions'
import { updateAppId } from 'utils/localStorage'
import './rollbar'
import './storage'
import BrowserFingerprintHash from './fingerprint'

if (
  /localhost|(127\.0\.0\.1)/.test(window.location.href) &&
  !window.ToothpicOptions?.appId
) {
  window.ToothpicOptions = {
    appId: 'fab5082db558078738c130dbcfdf5d8b8d909d1d'
  }
}

window.__version = process.env.REACT_APP_VERSION

export default () => {
  let options

  try {
    // no need to wait for this
    BrowserFingerprintHash()
    options = JSON.parse(getQueryParameterByName('options'))
  } catch (e) {
    return Promise.reject(
      new Error(
        'App ID could not be found! Please check your config, and contact Toothpic support if this issue persists.'
      )
    )
  }
  if (!options?.appId) {
    try {
      options = getWindowToothpicOptions()
    } catch (e) {
      return Promise.reject(e)
    }
  }
  window.ToothpicOptions = Object.assign(
    {},
    window.ToothpicOptions || {},
    options
  )
  if (!window.ToothpicOptions.appId) {
    return Promise.reject(
      new Error(
        'App ID could not be found! Please check your ToothpicOptions configuration, ' +
          'and contact support@toothpic.com if this issue persists.'
      )
    )
  }

  return updateAppId(window.ToothpicOptions.appId)
}
