/**
 *
 * MessageInput
 *
 */

import { UserInteractionContext } from 'contexts/UserInteractionContext'
import * as PropTypes from 'prop-types'
import { TYPING_TIMEOUT } from './config'
import React, { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import SendIcon from '@material-ui/icons/Send'

const InputForm = styled.form`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  transition: border-radius 0.3s ease-out;
  fieldset {
    display: flex;
    flex-direction: row;
    border: none;
    outline: none;
    padding: 0 !important;
    margin: 0;
  }
`

const Input = styled.textarea`
  --primary: #25acaf;
  resize: none;

  border-radius: ${({ hasFocused }) => `${hasFocused ? 0 : 8}px`};
  flex: 1;
  padding: 8px 48px 8px 8px;
  min-height: 48px;
  outline: none;
  border-top: ${({ hasFocused })=>hasFocused ? '1px solid lightgray' : 'none'};
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  font-family: Open-Sans, sans-serif;

  &:disabled {
    outline: none;
    background: white;
  }

  &:active {
    outline: none;
    border-top: 1px solid ${({ theme }) => theme.colors.primary};
  }

  &:focus {
    outline: none;
    border-top: 1px solid var(--primary);
  }
`

const PlaceholderDiv = styled.div`
  width: 100%;
  display: block;
  background: transparent;
  min-height: 48px;
`

const SendButton = styled.button`
  --primary: #25acaf;
  color: var(--primary);

  width: 36px;
  height: 36px;
  position: absolute;

  right: 8px;
  top: 16px;
  border: none;
  background: transparent;
  outline: none;

  &:disabled {
    color: lightgray;
  }
  &:active {
    color: var(--primary);
  }
`

const FieldsetDiv = styled.div`
  display: flex;
  flex: 1;
  position: relative;

  ${({ hasFocused }) => `${hasFocused ? 'none' : ' box-shadow: 0px 0px 2px rgba(0,0,0,0.2)'}; border-radius: 8px;`}
  ${SendButton} {
    position: absolute;
    right: 0;
  }
`

function MessageInput({
  disableInput,
  isClosed,
  onFocus,
  onSubmit,
  typingCallback
}) {
  const spacerRef = useRef()
  const inputRef = useRef()
  const submitMessage = e => {
    e && e.preventDefault()
    if (!disableInput) {
      const val = inputRef.current?.value
      if (val && val.length) {
        onSubmit(val.trim())
        inputRef.current.value = ''
      }
    }
  }

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      submitMessage()
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const val = inputRef.current?.value
      if (val && val.length) {
        typingCallback()
      }
    }, TYPING_TIMEOUT)
    return () => {
      interval && clearInterval(interval)
    }
  }, [typingCallback])

  const { hasFocused } = useContext(UserInteractionContext)

  return (
    <>
      <PlaceholderDiv style={{ height: spacerRef.current?.clientHeight }} />
      <InputForm onSubmit={submitMessage} ref={spacerRef}>
        <fieldset disabled={disableInput}>
          <FieldsetDiv hasFocused={hasFocused}>
            <Input
              ref={inputRef}
              type="text"
              rows={3}
              placeholder={
                isClosed ? 'Conversation is closed' : 'Type a message'
              }
              name="new_message"
              autocomplete="off"
              autoComplete="off"
              onFocus={onFocus}
              onKeyPress={onKeyPress}
              hasFocused={hasFocused}
            />
            <SendButton type="submit">
              <SendIcon />
            </SendButton>
          </FieldsetDiv>
        </fieldset>
      </InputForm>
    </>
  )
}

MessageInput.propTypes = {
  disableInput: PropTypes.bool,
  isClosed: PropTypes.bool,
  onFocus: PropTypes.func,
  onSubmit: PropTypes.func,
  typingCallback: PropTypes.func
}

MessageInput.defaultProps = {
  disableInput: false,
  isClosed: false
}

export default MessageInput
