/*
 *
 * AccountGate actions
 *
 */

import {
  DEFAULT_ACTION,
  LOAD_PRACTICE_ACCOUNT,
  LOAD_PRACTICE_ACCOUNT_FAILURE,
  LOAD_PRACTICE_ACCOUNT_SUCCESS
} from "./constants";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function loadPracticeAccount(payload) {
  return {
    type: LOAD_PRACTICE_ACCOUNT,
    payload
  };
}

export function loadPracticeAccountSuccess(payload) {
  return {
    type: LOAD_PRACTICE_ACCOUNT_SUCCESS,
    payload
  };
}

export function loadPracticeAccountFailure(error, payload, response) {
  return {
    type: LOAD_PRACTICE_ACCOUNT_FAILURE,
    error,
    payload,
    response
  };
}
