import { MINIMUM_LOADING_TIMEOUT } from 'app-constants'
import Loader from 'components/Loader'
import React from 'react'

const Component = React.lazy(() =>
  Promise.all([import('./index'), setTimeout(()=>{},MINIMUM_LOADING_TIMEOUT)]).then(
    ([moduleExports]) => moduleExports
  )
)

export default props => (
  <React.Suspense fallback={<Loader  key='loader' />}>
    <Component {...props} />
  </React.Suspense>
)
