import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'

import ChatMessagesReducer from './containers/ChatFeed/reducer'
import ConversationsReducer from './containers/Conversations/reducer'
import LeadReducer from './containers/Lead/reducer'
import ConnectedMessageReducer from './containers/ConnectedMessage/reducer'
import PracticeAccountReducer from './containers/AccountGate/reducer'

import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

const version = process.env.REACT_APP_VERSION

const persistConfigRoot = {
  key: 'root',
  storage,
  blacklist: ['conversations', 'chatMessages', 'lead', 'connectedMessages'],
  version,
  stateReconciler: autoMergeLevel2
}

const persistConfigConversations = {
  key: 'conversations',
  storage,
  blacklist: ['typing'],
  version,
  stateReconciler: autoMergeLevel2
}
const persistConfigChatMessages = {
  key: 'chatMessages',
  storage,
  blacklist: [],
  version,
  stateReconciler: autoMergeLevel2
}
const persistConfigLead = {
  key: 'lead',
  storage,
  blacklist: ['hasFetchedConversations', 'leadConversationsLoading'],
  version,
  stateReconciler: hardSet
}

const persistConfigConnected = {
  key: 'connectedMessages',
  storage,
  version,
  stateReconciler: hardSet
}

const persistConfigPractice = {
  key: 'practiceAccount',
  storage,
  version,
  stateReconciler: hardSet
}

export const rootReducer = combineReducers({
  chatMessages: persistReducer(persistConfigChatMessages, ChatMessagesReducer),
  conversations: persistReducer(
    persistConfigConversations,
    ConversationsReducer
  ),
  lead: persistReducer(persistConfigLead, LeadReducer),
  connectedMessages: persistReducer(
    persistConfigConnected,
    ConnectedMessageReducer
  ),
  practiceAccount: persistReducer(persistConfigPractice, PracticeAccountReducer)
})

export default persistReducer(persistConfigRoot, rootReducer)
