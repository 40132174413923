/*
 *
 * ChatFeed reducer
 *
 */

import { CREATE_CONVERSATION_SUCCESS, LOAD_CONVERSATIONS_LIST_SUCCESS } from "../../containers/Conversations/constants";
import uuid from "uuid";

import {
  MESSAGE_SEEN,
  MESSAGE_SEEN_CLEAR,
  MESSAGE_SEEN_SUCCESS,
  MESSAGE_READ,
  MESSAGE_READ_SUCCESS,
  SET_TYPING
} from "containers/ConnectedMessage/constants";
import {
  DEFAULT_ACTION,
  FETCH_CHAT_FEED,
  FETCH_CHAT_FEED_FAILURE,
  FETCH_CHAT_FEED_SUCCESS,
  REMOVE_CHAT_MESSAGE,
  SEND_NEW_CHAT_MESSAGE,
  SEND_NEW_CHAT_MESSAGE_FAILURE,
  SEND_NEW_CHAT_MESSAGE_SUCCESS,
  UPDATE_CONVERSATION,
  UPDATE_CONVERSATION_FAILURE,
  UPDATE_CONVERSATION_SUCCESS
} from "./constants";

import {
  _addMessage, _loadChatFeedFailure,
  _loadChatFeedSuccess,
  _removeMessage,
  _replaceNewMessage,
  _setTyping,
  _startLoadChatFeed,
  _updateMessage, chatMessageToChatFeedItem
} from "./reducer2";

export const initialState = {
  loading: false,
  error: null,
  conversations: {
    new: { ids: [], idMap: {} }
  },
  messages: {},
  typing: {},
  total: 0,
  lTotal: 0,
  unseen: {},
  seen: {}
};

/*
 *
 * ChatFeed reducer
 *
 */
function chatMessagesReducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case DEFAULT_ACTION:
      return state;

    case CREATE_CONVERSATION_SUCCESS:
      return addConversation(state, payload);

    case SET_TYPING:
      return _setTyping(state, payload);

    case FETCH_CHAT_FEED:
      return _startLoadChatFeed(state, payload);

    case FETCH_CHAT_FEED_SUCCESS:
      return _loadChatFeedSuccess(state, payload);

    case FETCH_CHAT_FEED_FAILURE:
      return _loadChatFeedFailure(state, payload, error);

    case SEND_NEW_CHAT_MESSAGE:
      return _addMessage(state, chatMessageToChatFeedItem({
        ...payload,
        sending: true
      }))

    case SEND_NEW_CHAT_MESSAGE_SUCCESS:
      return _replaceNewMessage(state, payload);

    case SEND_NEW_CHAT_MESSAGE_FAILURE:
      return _updateMessage(state, { ...payload, error: error.message || error, sending: false });

    case REMOVE_CHAT_MESSAGE:
      return _removeMessage(state, payload.id, payload.conversation_id);

    case MESSAGE_READ:
      return _updateMessage(state, {
        ...payload,
        seen: true,
        seen_at: new Date().toISOString()
      });

    case MESSAGE_READ_SUCCESS:
      return _updateMessage(state, {
        ...payload,
        seen: true,
        seen_at: new Date().toISOString()
      });

    case MESSAGE_SEEN:
      return _updateMessage(state, {
        ...payload,
        seen: true,
        seen_at: new Date().toISOString()
      });

    case MESSAGE_SEEN_SUCCESS:
      return _updateMessage(state, {
        ...payload,
        seen: true,
        seen_at: new Date().toISOString()
      });

    case MESSAGE_SEEN_CLEAR:
      return state;

    case UPDATE_CONVERSATION:
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.conversationId]: {
            ...state.conversations[payload.conversationId],
            loading: true,
            error: null
          }
        }
      };

    case UPDATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.id]: {
            ...state.conversations[payload.conversationId],
            ...payload,
            loading: false,
            error: null
          }
        }
      };

    case UPDATE_CONVERSATION_FAILURE:
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.id]: {
            ...state.conversations[payload.conversationId],
            error,
            loading: false
          }
        }
      };

    case LOAD_CONVERSATIONS_LIST_SUCCESS:
      return payload.data.reduce(addConversation, state)

    default:
      return state;
  }
}

function addConversation(state, conversation) {

  const c = {
    ...conversation,
    ids: [],
    idMap: {}
  }
  return {
    ...state,
    conversations: {
      ...state.conversations,
      [conversation.id]: {
        ...state.conversations[conversation.id],
        ...c
      }
    }
  };
}

export default chatMessagesReducer;
