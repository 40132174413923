
import { UserInteractionContext } from 'contexts/UserInteractionContext'
import Logo from 'components/Logo'
import PracticeAccountContext from 'containers/AccountGate/context'
import React, { useContext } from 'react'
import styled from 'styled-components'

export const HeaderHeight = 64

const AppBar = styled.div`
  background-color: #1d8a8d;
  color: white;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding: 0 16px;
  height: ${HeaderHeight}px;
  align-items: center;

  ${({ hasFocused }) =>
    hasFocused
      ? `display: flex; visibility: visible;`
      : `visibility: hidden; display: none;`}

  p {
    font-size: 16px;
    flex: 1;
  }
`

const LOGO_SIZE = 48

const StyledToothpicLogo = styled(Logo)`
  height: ${LOGO_SIZE}px;
  width: ${LOGO_SIZE}px;
  float: right;
`

const StyledLogo = styled.img`
  height: ${LOGO_SIZE}px;
  width: ${LOGO_SIZE}px;
  display: inline-block;
  float: right;
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
`

// TODO: This image is re-loading and I don't know why
class StyledLog extends React.PureComponent {
  render() {
    return <StyledLogo {...this.props} />
  }
}

const ConnectionStatus = styled.span`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 1px solid ${props => (props.connected ? '#23cacd' : 'lightgray')};
  background-color: ${props => (props.isOnline ? '#23cacd' : 'lightgray')};
  display: inline-block;
  margin-right: 8px;
`

const HeaderTitle = styled.p`
  font-size: 1em;
`
/**
 * connected = socket connected
 * isOnline = other user is online
 */
export default function ChatHeader({ connected, isOnline, userTyping }) {
  const {
    account = { practice_display_name: 'Talk to us', practice_logo_url: '' }
  } = useContext(PracticeAccountContext)
  const { hasFocused } = useContext(UserInteractionContext)
  return (
    <AppBar hasFocused={hasFocused}>
      <ConnectionStatus connected={connected} isOnline={isOnline} />
      <HeaderTitle>
        {userTyping ? <i>{'typing...'}</i> : account?.practice_display_name}
      </HeaderTitle>
      {account?.practice_logo_url ? (
        <StyledLog url={account.practice_logo_url} />
      ) : (
        <StyledToothpicLogo />
      )}
    </AppBar>
  )
}
