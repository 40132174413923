import ChatMessages from '../containers/ChatFeed'
import Conversations  from '../containers/Conversations'
import Lead  from '../containers/Lead'
import UserSwitch from '../components/UserSwitch'
import { HashRouter, Route } from 'react-router-dom'
import React from 'react'

export default ()=> {
  return (<HashRouter basename="/">
    <Route exact path="/" component={UserSwitch}/>
    <Route exact path="/lead" component={Lead}/>
    <Route exact path="/lead/:id" component={Lead}/>
    <Route exact path="/conversations" component={Conversations}/>
    <Route exact path="/conversation/:id" component={ChatMessages}/>
  </HashRouter>)
}
