/*
 *
 * AccountGate constants
 *
 */

export const DEFAULT_ACTION = 'src/AccountGate/DEFAULT_ACTION';
export const LOAD_PRACTICE_ACCOUNT = 'src/AccountGate/LOAD_PRACTICE_ACCOUNT';
export const LOAD_PRACTICE_ACCOUNT_SUCCESS = 'src/AccountGate/LOAD_PRACTICE_ACCOUNT_SUCCESS';
export const LOAD_PRACTICE_ACCOUNT_FAILURE = 'src/AccountGate/LOAD_PRACTICE_ACCOUNT_FAILURE';
