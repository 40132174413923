/*
 *
 * Conversations actions
 *
 */

import {
  DEFAULT_ACTION,
  START_POLL_CONVERSATIONS,
  LOAD_CONVERSATIONS_LIST,
  LOAD_CONVERSATIONS_LIST_SUCCESS,
  LOAD_CONVERSATIONS_LIST_FAILURE,
  LOAD_COMPANIES,
  LOAD_COMPANIES_SUCCESS,
  LOAD_COMPANIES_FAILURE,
  SET_CURRENT_CONVERSATION_ID,
  GET_CONVERSATION,
  GET_CONVERSATION_FAILURE,
  GET_CONVERSATION_SUCCESS, CREATE_CONVERSATION_SUCCESS, CREATE_CONVERSATION_FAILURE, CREATE_CONVERSATION
} from "./constants";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}

export function startPollConversations(assessorId) {
  return {
    type: START_POLL_CONVERSATIONS,
    payload: { assessorId }
  };
}

export function loadConversationsList(assessorId) {
  return {
    type: LOAD_CONVERSATIONS_LIST,
    payload: { assessorId }
  };
}

export function loadConversationsListSuccess(payload) {
  return {
    type: LOAD_CONVERSATIONS_LIST_SUCCESS,
    payload
  };
}

export function loadConversationsListFailure(error) {
  return {
    type: LOAD_CONVERSATIONS_LIST_FAILURE,
    error
  };
}
export function setCurrentConversation(id) {
  return {
    type: SET_CURRENT_CONVERSATION_ID,
    payload: { id }
  };
}

export function loadCompanies(payload) {
  return {
    type: LOAD_COMPANIES,
    payload
  };
}

export function loadCompaniesSuccess(payload) {
  return {
    type: LOAD_COMPANIES_SUCCESS,
    payload
  };
}

export function loadCompaniesFailure(error) {
  return {
    type: LOAD_COMPANIES_FAILURE,
    error
  };
}

export function getConversation(conversationId, hasNewMessages) {
  return {
    type: GET_CONVERSATION,
    payload: { conversationId, hasNewMessages }
  };
}

export function getConversationSuccess(payload) {
  return {
    type: GET_CONVERSATION_SUCCESS,
    payload
  };
}

export function getConversationFailure(error) {
  return {
    type: GET_CONVERSATION_FAILURE,
    error
  };
}

export function createConversation({ lead_id, member_id, assessor_id }) {
  return {
    type: CREATE_CONVERSATION,
    payload: { lead_id, member_id, assessor_id }
  };
}

export function createConversationSuccess(payload) {
  return {
    type: CREATE_CONVERSATION_SUCCESS,
    payload
  };
}

export function createConversationFailure(error, payload) {
  return {
    type: CREATE_CONVERSATION_FAILURE,
    error
  };
}
