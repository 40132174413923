import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the lead state domain
 */

const selectLeadDomain = state =>
  state.lead || initialState

/**
 * Other specific selectors
 */

const selectLeadId = () => createSelector(selectLeadDomain, substate => substate.leadId)
const selectLead = () => createSelector(selectLeadDomain, substate => substate.lead)
const selectCurrentConversationId = () => {
  return createSelector(selectLeadDomain, substate => substate.currentConversationId)
}
const selectConversationsError = () => createSelector(selectLeadDomain, substate => substate.leadConversationsError)
const selectConversationCreateError = () => createSelector(selectLeadDomain, substate => substate.leadConversationCreateError)
const selectLeadError = () => createSelector(selectLeadDomain, substate => substate.leadError)
const selectLoading = () => createSelector(selectLeadDomain, substate => substate.loading)
const selectLeadConversationLoading = () => createSelector(selectLeadDomain, substate => substate.leadConversationsLoading)
const selectFetchedLeadConversations = () => createSelector(selectLeadDomain, substate => substate.hasFetchedConversations)
const selectNewChatMessages = () => createSelector(selectLeadDomain, substate => substate.newChatMessages)
const selectHasOpenConversation = () => createSelector(selectLeadDomain, substate => substate.hasOpen)
const selectHasClosedConversation = () => createSelector(selectLeadDomain, substate => substate.hasClosed)
/**
 * Default selector used by Lead
 */

const makeSelectLead = () =>
    createSelector(selectLeadDomain, substate => substate);

export default makeSelectLead;
export {
  selectLeadDomain,
  selectCurrentConversationId,
  selectLoading,
  selectLead,
  selectLeadId,
  selectFetchedLeadConversations,
  selectLeadConversationLoading,
  selectNewChatMessages,
  selectLeadError,
  selectHasOpenConversation,
  selectHasClosedConversation,
  selectConversationsError,
  selectConversationCreateError
};
