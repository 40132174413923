import * as Fingerprint2 from 'fingerprintjs2' // sets window object...
let bfp = window.localStorage.getItem('tp-bfp')

const FPrint = () => {
  return Fingerprint2.getPromise({})
    .then(function (components) {
      const values = components.map(function (component) {
        return component.value
      })
      const murmur = Fingerprint2.x64hash128(values.join(''), 31)
      bfp = murmur
      window.localStorage.setItem('tp-bfp', bfp)
      return bfp
    })
}
export default function BrowserFingerprintHash () {
  if (bfp) {
    return Promise.resolve(bfp)
  }
  return new Promise((resolve, reject) => {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(function () {
        FPrint().then(resolve).catch(reject)
      })
    } else {
      setTimeout(function () {
        FPrint().then(resolve).catch(reject)
      }, 300)
    }
  })
}
