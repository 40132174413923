import { selectCampaignIdentifier } from "containers/AccountGate/selectors";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import request, { requestPage } from "utils/request";
import { getMessageBody } from "../../utils/chatFeedItem";
import { getChatMessages } from "../ChatFeed/saga";
import {
  createConversationFailure,
  createConversationSuccess,
  getConversationFailure,
  getConversationSuccess,
  loadCompaniesFailure,
  loadCompaniesSuccess,
  loadConversationsListFailure,
  loadConversationsListSuccess
} from "./actions";
import {
  CREATE_CONVERSATION,
  GET_CONVERSATION,
  LOAD_COMPANIES,
  LOAD_CONVERSATIONS_LIST
} from "./constants";
import { selectConversation } from "./selectors";

const MESSAGES_IN_PREVIEW = 1;

function* getConversationMessagePreview(conversation) {
  const { id, total_message_count: count } = conversation;
  let messages;
  if (!count) {
    messages = { data: [], total: 0 };
  } else {
    // messages = { data: [], total: 0 };
    messages = yield getChatMessages({
      conversationId: id,
      limit: MESSAGES_IN_PREVIEW,
      offset: 0
    });
  }

  return Object.assign(conversation, {
    preview: messages.data.length
      ? messages.data
          .slice(0, MESSAGES_IN_PREVIEW)
          .reduce((memo, m) => `${memo}${getMessageBody(m)}\n`, "")
      : "",
    last_message: messages.data[0]?.created_at || null,
    hidden: messages.data.length === 0,
    hasUnread: messages.hasUnread
  });
}

export function* getConversationsList({
  payload: { offset = 0, limit = 100, sort = "updated_at:DESC", params = "" }
}) {
  const endpoint = "v3/conversations";
  const headers = new Headers();
  const options = {
    method: "GET",
    headers,
    mode: "cors"
  };
  const config = { offset, limit, sort, params };

  try {
    const response = yield call(requestPage, endpoint, options, config);
    if (response.status === 401) {
      return yield put(loadConversationsListSuccess({ data: [], total: 0 }));
    }
    const conversations = yield all(
      response.data.map(conversation =>
        getConversationMessagePreview(conversation)
      )
    );
    const ret = {
      ...response,
      data: conversations
    };
    yield put(loadConversationsListSuccess(ret));
    return ret;
  } catch (e) {
    yield put(loadConversationsListFailure(e));
  }
}

export function* fetchConversation(conversationId) {
  const endpoint = `v3/conversations/${conversationId}`;
  const headers = new Headers();
  headers.append("X-Schema", "dental_practices");
  const options = {
    method: "GET",
    headers,
    mode: "cors"
  };

  const response = yield call(request, endpoint, options);
  return response.data;
}

export function* getConversationAndPreview({ payload }) {
  try {
    const { conversationId, hasNewMessages } = payload;
    let conversation = yield select(selectConversation(conversationId));
    if (!conversation) {
      conversation = yield fetchConversation(conversationId);
    } else if (hasNewMessages) {
      conversation.total_message_count = conversation.total_message_count + 1;
    }
    conversation = yield getConversationMessagePreview(conversation);
    return yield put(getConversationSuccess(conversation));
  } catch (e) {
    console.error(e);
    yield put(
      getConversationFailure(
        { message: e.message },
        { conversationId: payload.conversationId }
      )
    );
  }
}

export function* loadCompanies() {
  const endpoint = "v3/companies";
  const headers = new Headers();
  const options = {
    method: "GET",
    headers,
    mode: "cors"
  };

  try {
    const response = yield call(request, endpoint, options);
    yield put(loadCompaniesSuccess(response));
  } catch (e) {
    yield put(loadCompaniesFailure({ message: e.message }));
  }
}

export function* createConversation({ payload }) {
  const body = payload;
  const endpoint = "v3/conversations";
  const headers = new Headers();
  const campaignId = yield select(selectCampaignIdentifier());
  if (!campaignId) throw new Error("Campaign Identifier is required");
  body.campaign_identifier = campaignId;
  const options = {
    method: "POST",
    headers,
    mode: "cors",
    body: JSON.stringify(body)
  };

  try {
    const response = yield call(request, endpoint, options);
    yield put(createConversationSuccess(response));
  } catch (e) {
    yield put(createConversationFailure({ message: e.message || e }));
  }
}

export default function* defaultSaga() {
  yield takeLatest(LOAD_CONVERSATIONS_LIST, getConversationsList);
  yield takeLatest(GET_CONVERSATION, getConversationAndPreview);
  yield takeLatest(LOAD_COMPANIES, loadCompanies);
  yield takeLatest(CREATE_CONVERSATION, createConversation);
}
